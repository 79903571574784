//web server base url
export const API_BASE_URL = "https://dev.api.lobees.com/api";

export const SERVER_URL = "https://dev.api.lobees.com";

export const BACKEND_BASE_URL = "https://dev.admin.lobees.com"; // Backend url

export const FRONTEND_BASE_URL = "https://dev.lobees.com";

// --------------------------------LOCAL SERVER -------------------------------------
//local server url
// export const API_BASE_URL = "http://localhost:4006/api"; // NODE SERVER URL FOR API

// export const SERVER_URL = "http://localhost:4006";

// export const BACKEND_BASE_URL = "http://localhost:4600"; // Backend urlgit

// export const FRONTEND_BASE_URL = "http://localhost:4204"; // For frontend

/*=========== all Demo Data API URL start here ===========*/
export const DEMO_GET = "/demo/demoget"; // for get demo data

export const DEMO_POST = "/demo/demopost"; // for testing post data
/*=========== all Demo Data API URL end here ===========*/

/*======= login and registration url start =======*/
export const API_LOGIN = "/auth/login"; //login url

export const API_REGISTRATION = "/auth/register"; //registration url

export const API_GET_OTP = "/auth/getotp"; //for getting otp

export const API_VALIDATE_OTP = "/auth/validateotp"; //for validating otp

export const API_UPDATE_PASSSWORD = "/auth/updatepassword"; //for update passowrd

export const API_AUTH_VALIDATE = "/auth/validateuser"; //validation url

export const API_UNSUBSCRIBE_TO_MAIL = "/message/unsubsribetomail"; //unsubscribe mail

export const API_SUBSCRIBE_TO_MAIL = "/message/subscribetomail"; //subscribe mail

export const API_REDSYS_CREATE_PAYMENT = "/redsys/pay/create-payment"; //create Redsys payment

/*======= login and registration url end =======*/

/*======= File upload url start =======*/
export const API_SINGLE_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_MULTIPLE_FILE_UPLOAD = "/upload/multiupload"; //for multiple file upload

/*======= File upload url end =======*/

/*======= URL to upload Image in Text Editor start =======*/
export const URL_TEXT_EDITOR_IMAGE_UPLOAD =
  "https://dev.lobees.com/web/uploads/documents/";

export const URL_TEXT_EDITOR_IMAGE_UPLOAD_SECOND =
  "https://dev.lobees.com/web/uploads/reports/";
/*======= URL to upload Image in Text Editor end =======*/

// role url start

//add role
export const API_ADD_ROLE = "/role/createrole";

export const API_GET_ALL_ROLES = "/role/getroles"; //get app user roles

//get role details
export const API_GET_ROLE_DETAILS = "/role/getroledetails";

//update role
export const API_UPDATE_ROLE = "/role/updaterole";
// role url end

//add Plan --------------------------------------
export const API_ADD_PLAN = "/plan/create";

// get all plans
export const API_GET_ALL_PLANS = "/plan/getall"; //get app user roles

//get plan details
export const API_GET_PLAN_DETAILS = "/plan/getdetails";

//update plan
export const API_UPDATE_PLAN = "/plan/update";

// category url start
//add category
export const API_ADD_CATEGORY = "/category/createcategory";

//get all Categories
export const API_GET_CATEGORIES = "/category/getcategories";

//get category details
export const API_GET_CATEGORY_DETAILS = "/category/getcategorydetails";

//update category
export const API_UPDATE_CATEGORY = "/category/updatecategory";

// category url end

/* module url start */
//add module
export const API_CREATE_MODULE = "/module/createmodule";

//get modules
export const API_GET_ALL_MODULES = "/module/getallmodules";

//get module details
export const API_GET_MODULE_DETAILS = "/module/getmoduledetails";

//update module
export const API_UPDATE_MODULE = "/module/updatemodule";

/* module url end */

// module roles url start
//add module
export const API_CREATE_MODULE_ROLE = "/modulerole/createmodulerole";

//get modules
export const API_GET_ALL_MODULE_ROLE = "/modulerole/getallmoduleroles";

//get module details
export const API_GET_MODULE_ROLE_DETAILS = "/modulerole/getmoduleroledetails";

//update module
export const API_UPDATE_MODULE_ROLE = "/modulerole/updatemodulerole";

// module roles url end

//module role previlege url
//update
export const API_UPDATE_MODULE_ROLE_PREVILEGE =
  "/moduleroleprevilege/updatemoduleroleprevilege";

//get all
export const API_GET_ALL_MODULE_ROLE_PREVILEGES =
  "/moduleroleprevilege/getallmoduleroleprevilege";

//get all
export const API_GET_ALL_MODULE_ACCESS = "/moduleroleprevilege/getmoduleaccess";

/* ecosysyem url start */
//add ecosystem
export const API_ADD_ECOSYSTEM = "/ecosystem/createecosystem";

//get all ecosystem
export const API_GET_ALL_ECOSYSTEM = "/ecosystem/getecosystems";

//get details
export const API_GET_ECOSYSTEM_DETAILS = "/ecosystem/getecosystemdetails";

//switch ecosystem
export const API_SWITCH_ECOSYSTEM = "/ecosystem/switchecosystem";

//update ecosystem
export const API_UPDATE_ECOSYSTEM = "/ecosystem/updateecosystem";

/* ecosysyem url end */

//get all event roles
export const API_GET_ALL_MODULE_ROLES = "/modulerole/getallmoduleroles";

/*----- tag/label url start ------*/
//create tag
export const API_CREATE_NEW_TAG = "/tag/createtag";

//get all tag
export const API_GET_ALL_TAGS = "/tag/getalltags";

//get tag details
export const API_GET_TAG_DETAILS = "/tag/gettagdetails";

//update tag
export const API_UPDATE_TAG = "/tag/updatetag";

/*----- tag/label url end ------*/

/*====== skillpoint url start ======*/
//add new skillpoint
export const API_CREATE_NEW_SKILLPOINT = "/skillpoint/addskillpoint";

//get skillpoint details
export const API_GET_SKILLPOINT_DETAILS = "/skillpoint/getskillpointdetails";

//update skillpoint
export const API_UPDATE_SKILLPOINT = "/skillpoint/updateskillpoint";

//delete skillpoint
export const API_DELETE_SKILLPOINT = "/skillpoint/deleteskillpoint";

/*====== skillpoint url end ======*/

/*====== lead url start ======*/
//create lead
export const API_CREATE_LEAD = "/lead/createlead";

//purchase lead
export const API_PURCHASE_LEAD = "/lead/purchaselead";

//create note
export const API_CREATE_LEAD_NOTE = "/lead/createnote";

//verifyleademails
export const API_VERIFY_LEAD_EMAILS = "/lead/verifyleademails";

//linkedin helper
export const API_LINKEDIN_HELPER = "/lead/linkedinhelper";

//get all lead
export const API_GET_ALL_LEADS = "/lead/getallleads";

//getallleadoptions
export const API_GET_ALL_LEAD_OPTIONS = "/lead/getallleadoptions";

//getallecoleads
export const API_GET_ALL_ECO_LEADS = "/lead/getallecoleads";

//get all note
export const API_GET_LEAD_NOTES = "/lead/getnotes";

//get lead details
export const API_GET_LEAD_DETAILS = "/lead/getleaddetails";

//update lead
export const API_UPDATE_LEAD = "/lead/updatelead";

//update note
export const API_UPDATE_LEAD_NOTE = "/lead/updatenote";

//delete lead
export const API_DELETE_LEAD = "/lead/deletelead";

//delete note
export const API_DELETE_LEAD_NOTE = "/lead/deletenote";

//add/remove tag lead
export const API_ADD_REMOVE_TAG_LEAD = "/lead/addremovetaglead";

//addremovefollowerslead
export const API_ADD_REMOVE_FOLLOWERS_LEAD = "/lead/addremovefollowerslead";
/*====== lead url end ======*/

/*====== contact url start ======*/

/* search by name or email to get 
follower and member options in 
company,task,project,event,group,process,invoice etc .. */
export const API_GET_USERLIST_OPTIONS = "/contact/getuserlistforoptions";

//send connect request
export const API_SEND_REQUEST_USER = "/contact/sendinvite";

//get all contact
export const API_GET_ALL_CONTACTS = "/contact/getallcontacts";

//get all connect invites
export const API_GET_ALL_RECIEVED_INVITE = "/contact/getallrecievedinvites";

//get all send invite list
export const API_GET_ALL_SEND_INVITE = "/contact/getallsendinvites";

//accept/reject invite
export const API_ACCEPT_INVITE = "/contact/updatecontact";

//cancel/reject request
export const API_REJECT_CONTACT_REQUEST = "/contact/rejectrequest";

//searchcontact
export const API_SEARCH_CONTACT = "/contact/searchcontact";

//get all contact for availibility calendar in calendar
export const API_GET_ALL_CONTACTS_OPTIONS = "/contact/getallcontactsforoptions";

//create note
export const API_CREATE_CONTACT_NOTE = "/contact/createnote";

//get contact details
export const API_GET_CONTACT_DETAILS = "/contact/getcontactdetails";

//get all note
export const API_GET_CONTACT_NOTES = "/contact/getnotes";

//add tag
export const API_ADD_REMOVE_LABEL_CONTACT = "/contact/addtagtocontact";

//update note
export const API_UPDATE_CONTACT_NOTE = "/contact/updatenote";

//delete note
export const API_DELETE_CONTACT_NOTE = "/contact/deletenote";

//unfriend contact
export const API_UNFRIEND_CONTACT = "/contact/unfriendcontact";

//get timeline
export const API_GET_CONTACT_TIMELINE = "/contact/gettimeline";

/*====== contact url end ======*/

/*====== blog url start ======*/
//create new blog
export const API_CREATE_NEW_BLOG = "/blog/createblog";

//get all blog
export const API_GET_ALL_BLOG = "/blog/getallblogs";

//get blog details
export const API_GET_BLOG_DETAILS = "/blog/getblogdetails";

//update blog
export const API_UPDATE_BLOG = "/blog/updateblog";

//delete blog
export const API_DELETE_BLOG = "/blog/deleteblog";

//add or remove label/tag to blog
export const API_ADD_REMOVE_TAG_BLOG = "/blog/addremovetagblog";

/*====== blog url end ======*/

/*====== success story url start ======*/
//get all company list
export const API_GET_ALL_COMPANY_LIST = "/company/getcompanyselectlist";

//add new success story
export const API_ADD_NEW_SUCCESS_STORY = "/successstories/createsuccessstories";

//get all success story
export const API_GET_ALL_SUCCESS_STORIES =
  "/successstories/getallsuccessstories";

//get success story details
export const API_GET_SUCCESS_STORY_DETAILS =
  "/successstories/getsuccessstoriesdetails";

//update success story
export const API_UPDATE_SUCCESS_STORY = "/successstories/updatesuccessstories";

//delete success story
export const API_DELETE_SUCCESS_STORY = "/successstories/deletesuccessstories";

//add/remove label
export const API_ADD_REMOVE_TAG_SUCCESS_STORY =
  "/successstories/addremovetagsuccessstories";

/*====== success story url end ======*/

/*===== experts/appuser url start ======*/
//search user for add member in event,group
export const API_GET_USERLIST = "/account/getuserlist";

//add new user
export const API_ADD_NEW_USER = "/account/addnewuser";

//get all app users
export const API_GET_APP_USERS = "/account/getallappusers";

//add/remove tag to app user
export const API_ADD_REMOVE_TAG_APP_USER = "/account/addtagtouser";

//get app user details
export const API_GET_USER_DETAILS = "/account/userdetails";

//update app user
export const API_UPDATE_APP_USER = "/account/updateuser";

//changeuserpassword
export const API_CHANGE_USER_PASSWORD = "/account/changeuserpassword";

//add new day availibility
export const API_ADD_NEW_DAYS_AVAILIBILITY = "/account/adddayavailibility";

//save time availibility by day
export const API_ADD_TIME_AVAILIBILITY_OF_DAY = "/account/savetimeavailibility";

//getallavailibility
export const API_GET_ALL_DAYS_AVAILIBILITY = "/account/getallavailibility";

//getavailibilitydetails
export const API_GET_DAYS_AVAILIBILITY_DETAILS =
  "/account/getavailibilitydetails";

//updatetimeavailibility
export const API_UPDATE_TIME_AVAILIBILITY = "/account/updatetimeavailibility";

//deletetimeavailibility
export const API_DELETE_TIME_AVAILIBILITY = "/account/deletetimeavailibility";

/*===== experts/appuser url end ======*/

/*====== event url start ======*/
//get all event type
export const API_GET_ALL_EVENT_TYPES = "/eventtype/getalleventtypes";

//create event
export const API_CREATE_EVENT = "/event/createevent";

//get all event
export const API_GET_ALL_EVENTS = "/event/getallevents";

//get parnet events
export const API_GET_PARENT_EVENTS = "/event/getparentevents";

//get event list for select
export const API_GET_EVENT_LIST_OPTIONS = "/event/geteventlistforoptions";

//get event details
export const API_GET_EVENT_DETAILS = "/event/geteventdetails";

//geteventmemberdetailsbyquery
export const API_GET_EVENT_MEMBER_DETAILS_BY_QUERY =
  "/event/geteventmemberdetailsbyquery";

//update event
export const API_UPDATE_EVENT = "/event/updateevent";

//delete event
export const API_DELETE_EVENT = "/event/deleteevent";

//delete mass events
export const API_DELETE_EVENTS = "/event/deletemassevents";

//join event
export const API_JOIN_EVENT = "/event/joinevent";

//add/remove tag
export const API_ADD_REMOVE_TAG_EVENT = "/event/addremovetagevent";

//add member in event
export const API_ADD_NEW_EVENT_MEMBER = "/event/addnewmember";

//add sponsor
export const API_ADD_NEW_EVENT_COMPANY = "/event/addnewcompany";

//get all event member
export const API_GET_ALL_EVENT_MEMBER = "/event/getallmembers";

//getallsponsors
export const API_GET_ALL_EVENT_PARTNERS = "/event/getallsponsors";

//get member details
export const API_GET_EVENT_MEMBER_DETAILS = "/event/getmemberdetails";

//update event member
export const API_UPDATE_EVENT_MEMBER = "/event/updateeventmember";

//add/remove tag to event memeber
export const API_ADD_REMOVE_TAG_EVENT_MEMBER = "/event/addremovetageventmember";

//add gallery images
export const API_ADD_EVENT_GALLERY_IMAGES = "/event/addgalleryimage";

//delete gallery image
export const API_DELETE_EVENT_GALLERY_IMAGE = "/event/deletegalleryimage";

/*====== event url end ======*/

/*===== mail template url start ======*/
//get all survey for action link
export const API_GET_SURVEY_LIST = "/messagetemplate/getallsurveylist";

//create template
export const API_CREATE_MAIL_TEMPLATE = "/messagetemplate/create";

//get all template
export const API_GET_ALL_MAIL_TEMPLATES = "/messagetemplate/getall";

//get template details
export const API_GET_TEMPLATE_DETAILS = "/messagetemplate/getdetails";

//update template
export const API_UPDATE_MAIL_TEMPLATE = "/messagetemplate/update";

//add/remove tag template
export const API_ADD_TAG_TO_TEMPLATE = "/messagetemplate/addtagtotemplate";

/*===== mail template url end ======*/

/*====== company url start ======*/
//get all company type
export const API_GET_ALL_COMPANY_TYPE = "/companytype/getallcompanytype";

//add new company
export const API_ADD_NEW_COMPANY = "/company/addnewcompany";

//get all company
export const API_GET_ALL_COMPANY = "/company/getallcompanylist";

//getcompany by input search
export const API_GET_ALL_COMPANY_BY_SEARCH = "/company/getcompanyselectlist";

//get company details
export const API_GET_COMPANY_DETAILS = "/company/getcompanydetails";

//update company
export const API_UPDATE_COMPANY = "/company/updatecompany";

//delete company
export const API_DELETE_COMPANY = "/company/deletecompany";

//add/update tag company
export const API_ADD_REMOVE_TAG_COMPANY = "/company/addtagtocompany";

//add new member
export const API_ADD_NEW_COMPANY_MEMBER = "/company/addnewcompanymember";

//get all member
export const API_GET_ALL_COMPANY_MEMBERS = "/company/getallcompanymemberlist";

//get details of member
export const API_COMPANY_MEMBER_DETAILS = "/company/getcompanymemberdetails";

//update member
export const API_UPDATE_COMPANY_MEMBER = "/company/updatecompanymember";

//delete member
export const API_DELETE_COMPANY_MEMBER = "/company/deletecompanymember";

//add/update tag
export const API_ADD_REMOVE_TAG_COMPANY_MEMBER =
  "/company/addtagtocompanymember";

/*====== company url end ======*/

/*==== group url start =====*/
//create group
export const API_CREATE_GROUP = "/group/creategroup";

//add member in group
export const API_ADD_NEW_GROUP_MEMBER = "/group/addnewmember";

//get all group
export const API_GET_ALL_GROUPS = "/group/getallgroups";

//get all group options
export const API_GET_ALL_GROUP_OPTIONS = "/group/getgrouplist";

//get group details
export const API_GET_GROUP_DETAILS = "/group/getgroupdetails";

//get all group members
export const API_GET_ALL_GROUP_MEMBERS = "/group/getallmembers";

//get group member details
export const API_GET_GROUP_MEMBER_DETAILS = "/group/getmemberdetails";

//update group
export const API_UPDATE_GROUP = "/group/updategroup";

//update group member
export const API_UPDATE_GROUP_MEMBER = "/group/updategroupmember";

//add/remove tag to group
export const API_ADD_REMOVE_TAG_GROUP = "/group/addremovetaggroup";

//add gallery images
export const API_ADD_GROUP_GALLERY_IMAGES = "/group/addgalleryimage";

//delete gallery image
export const API_DELETE_GROUP_GALLERY_IMAGE = "/group/deletegalleryimage";

/*==== group url end =====*/

/*==== survey url start =====*/
//create survey
export const API_ADD_NEW_SURVEY = "/survey/createsurvey";

//savesurveytoinvite
export const API_SAVE_SURVEY_FEEDBACK = "/survey/savesurveyfeedback";

//invite user
export const API_INVITE_USER_SURVEY = "/survey/invitesurveytouser";

//get all survey
export const API_GET_ALL_SURVEY = "/survey/getallsurvey";

//getallsurveyinvites
export const API_GET_SURVEY_INVITES = "/survey/getallsurveyinvites";

//api get survey details
export const API_GET_SURVEY_DETAILS = "/survey/getsurveydetails";

// /getsurveyinvitedetails
export const API_GET_SURVEY_INVITE_DETAILS = "/survey/getsurveyinvitedetails";

//update survey
export const API_UPDATE_SURVEY = "/survey/updatesurvey";

export const API_SAVE_RESPONSE_SURVEY = "/survey/addresponsetosurvey";

export const API_GET_SURVEY_INVITE_RESPONSE =
  "/survey/getallsurveyinvitewithresponses";

//getallsurveyresponselist
export const API_GET_ALL_SURVEY_RESPONSE = "/survey/getallsurveyresponselist";

//tag update
export const API_ADD_REMOVE_TAG_SURVEY = "/survey/addremovetagsurvey";
export const API_ADD_REMOVE_TAG_SURVEY_INVITE =
  "/survey/addremovetagsurveyinvite";

//get all question type
export const API_GET_ALL_QUESTIONTYPES = "/questiontype/getallquestiontype";

//add question to survey
export const API_ADD_QUESTION_TO_SURVEY = "/survey/addquestiontosurvey";

//get question details
export const API_GET_QUESTION_DETAILS = "/survey/getquestiondetails";

//update question
export const API_UPDATE_QUESTION = "/survey/updatequestion";

//delete question from survey
export const API_DELETE_QUESTION = "/survey/deletequestion";

//deletemassivesurvey
export const API_DELETE_MASSIVE_SURVEY = "/survey/deletemassivesurvey";

//deletemassiveinvites
export const API_DELETE_MASSIVE_INVITES = "/survey/deletemassiveinvites";

/*==== survey url end =====*/

/*==== mail conversation url start ====*/
//create
export const API_ADD_MESSAGE = "/message/savemail";

//get all
export const API_GET_ALL_MESSAGE = "/message/getallmails";

//get recieved mail
export const API_GET_ALL_RECIEVED_MAIL = "/message/getallrecievedmails";

//get sent mail
export const API_GET_ALL_SENT_MAIL = "/message/getallsentmails";

//get all reserve mail
export const API_GET_ALL_RESERVE_MESSAGE = "/message/getallreservemail";

//get mail details
export const API_GET_MAIL_DETAILS = "/message/getmaildetails";

//update mail
export const API_UPDATE_MAIL = "/message/updatemail";

//delete
export const API_DELETE_MESSAGE = "/message/deletemail";

//add tag mail
export const API_ADD_TAG_MAIL = "/message/addtagtomessage";

/*==== mail conversation url end ====*/

/*==== project url start ====*/
//add proejct
export const API_ADD_NEW_PROJECT = "/project/addproject";

//copy project
export const API_COPY_PROJECT = "/project/copyproject";

//getuserlistfortask
export const API_GET_USER_FOR_TASK = "/project/getuserlistfortask";

//get all project
export const API_GET_ALL_PROJECTS = "/project/getallprojects";

//getprojectlsitbysearch
export const API_GET_PROJECTLIST_BY_SEARCH = "/project/getprojectlsitbysearch";

//getalltotalprojectmembers
export const API_GET_ALL_TOTAL_PROJECT_MEMBERS =
  "/project/getalltotalprojectmembers";

//get project details
export const API_GET_PROJECT_DETAILS = "/project/getprojectdetails";

//update proejct
export const API_UPDATE_PROJECT = "/project/updateproject";

//add remove tag project
export const API_ADD_REMOVE_TAG_PROJECT = "/project/addtagproject";

//add project member
export const API_ADD_PROJECT_MEMBER = "/project/addprojectmember";

//get all project members
export const API_GET_ALL_PROJECT_MEMBER = "/project/getallprojectmember";

//get project member details
export const API_GET_PROJECT_MEMBER_DETAILS =
  "/project/getprojectmemberdetails";

//getprojectmemberdetailsquery
export const API_GET_PROJECT_MEMBER_DETAILS_QUERY =
  "/project/getprojectmemberdetailsquery";

//update member
export const API_UPDATE_PROJECT_MEMBER = "/project/updateprojectmember";

//changememebrstatus
export const API_CHANGE_MEMBER_STATUS = "/project/changememebrstatus";

//add section
export const API_ADD_NEW_PROEJCT_SECTION = "/project/addsection";

//get all sections
export const API_GET_ALL_PROJECT_SECTION = "/project/getallprojectsections";

//getsectionlistoptions
export const API_GET_SECTION_LIST_OPTIONS = "/project/getsectionlistoptions";

//get section details
export const API_GET_SECTION_DETAILS = "/project/getsectiondetails";

//update section
export const API_UPDATE_PROJECT_SECTION = "/project/updateprojectsection";

//add task
export const API_ADD_NEW_PROJECT_TASK = "/project/addtask";

//update bulk task schedule
export const API_UPDATE_BULK_TASK_SCHEDULE = "/project/updatetaskbulkschedule";

//get all task
export const API_GET_ALL_TASKS = "/project/getalltasks";

//gettasklistbysearch
export const API_GET_TASKLIST_BY_SEARCH = "/project/gettasklistbysearch";

//get task details
export const API_GET_TASK_DETAILS = "/project/gettaskdetails";

//update task
export const API_UPDATE_PROJECT_TASK = "/project/updatetask";

//deactivatetask
export const API_DEACTIVATE_PROJECT_TASK = "/project/deactivatetask";

//updatetaskgantt
export const API_UPDATE_GANTT_TASK = "/project/updatetaskgantt";

//tag in task
export const API_ADD_TAG_TASK = "/project/addremovetagtask";

//add log
export const API_ADD_PROJECT_TASK_LOG = "/project/addtasklog";

//get all logs
export const API_GET_PROJECT_TASK_LOGS = "/project/getallprojectlogs";

//add label to task
export const API_ADD_TAG_TASK_LOG = "/project/addremovetagtasklog";

//get log details
export const API_GET_LOG_DETAILS = "/project/getlogdetails";

//update log
export const API_UPDATE_TASK_LOG = "/project/updatetasklog";

//accept invite by member
export const API_ACCEPT_INVITE_PROJECT_MEMBER = "/project/acceptinvitebymember";

//updated tag in project member
export const API_UPDATE_TAG_PROJECT_MEMBER = "/project/updatetagprojectmember";

//assignbulktasktouser
export const API_ASSIGN_BULK_TASK = "/project/assignbulktasktouser";

export const API_ADD_REMOVE_FOLLOWERS_TASK = "/project/addremovefollowerstask";
/*==== project url end ====*/

/*===== list url start =====*/
//get all list type
export const API_GET_LIST_TYPE = "/listtype/getalllisttype";

//create list
export const API_CREATE_LIST = "/list/addnewlist";

//get all list
export const API_GET_ALL_LIST = "/list/getalllist";

//get list details
export const API_LIST_DETAILS = "/list/getlistdetails";

//update list
export const API_UPDATE_A_LIST = "/list/updatelist";

//delete list
export const API_DELETE_A_LIST = "/list/deletelist";

//add tag list
export const API_ADD_REMOVE_TAG_LIST = "/list/addtagtolist";

/*===== list url end =====*/

/* challenge url start */
//create challenge
export const API_ADD_CHALLENGE = "/challenge/createchallenge";

//get all challenge
export const API_GET_ALL_CHALLENGE = "/challenge/getallchallenges";

//getchallengelistforoptions
export const API_GET_CHALLENGE_LIST_FOR_OPTIONS =
  "/challenge/getchallengelistforoptions";

//get details of challenge
export const API_GET_CHALLENGE_DETAILS = "/challenge/getchallengedetails";

//get all response
export const API_GET_ALL_CHALLENGE_RESPONSE =
  "/challenge/getallchallengeresponses";

//getchallengeresponsedetails
export const API_GET_CHALLENGE_RESPONSE_DETAILS = "/challenge/responsedetails";

//update challenge
export const API_UPDATE_CHALLENGE = "/challenge/updatechallenge";

//changeapprovalstatus
export const API_CHANGE_APPROVAL_STATUS = "/challenge/changeapprovalstatus";

//add label
export const API_ADD_LABEL_CHALLENGE = "/challenge/addremovetagchallenge";

//add new process
export const API_ADD_NEW_JOB_PROCESS = "/challenge/addnewjobprocess";

//getalljobprocess
export const API_GET_ALL_JOB_PROCESS = "/challenge/getalljobprocess";

//get process details
export const API_GET_JOB_PROCESS_DETAILS = "/challenge/getprocessdetails";

//update process
export const API_UPDATE_JOB_PROCESS = "/challenge/updatejobprocess";

//add new member
export const API_ADD_NEW_JOB_MEMBER = "/challenge/addchallengemember";

//get all member
export const API_GET_ALL_JOB_MEMBER = "/challenge/getallmembers";

//getjobmemberdetails
export const API_GET_JOB_MEMBER_DETAILS = "/challenge/getjobmemberdetails";

//updatechallengemember
export const API_UPDATE_JOB_MEMBER = "/challenge/updatechallengemember";

//addremovetagjobmemember
export const API_ADD_REMOVE_TAG_JOB_MEMBER =
  "/challenge/addremovetagjobmemember";

//addremovetag challengeresponse
export const API_ADD_REMOVE_TAG_CHALLENGE_RESPONSE =
  "/challenge/updatetagchallengeresponse";

//getcvdetails
export const API_GUEST_CV_DETAILS = "/challenge/guestchallengeresponsedetails";

/* challenge url end */

/* agenda url start */
//add new agenda
export const API_ADD_NEW_AGENDA = "/agenda/addnewagenda";

//add settings to agenda
export const API_SETTINGS_TO_AGENDA = "/agenda/addtoagenda";

//get all agenda
export const API_GET_ALL_AGENDA = "/agenda/getallagenda";

//get details
export const API_GET_DETAILS_AGENDA = "/agenda/getagendadetails";

//update agenda
export const API_UPDATE_AGENDA = "/agenda/updateagenda";

//delete agenda
export const API_DELETE_AGENDA = "/agenda/deleteagenda";

//tag
export const API_ADD_REMOVE_TAG_AGENDA = "/agenda/addremovetagagenda";
/* agenda url end */

// Invoice start
export const API_CREATE_INVOICE = "/invoice/createinvoice";

//getinvoicelistoptions
export const API_GET_INVOICE_LIST_OPTIONS = "/invoice/getinvoicelistoptions";

export const API_UPDATE_INVOICE = "/invoice/updateinvoice";

export const API_INVOICE_DETAILS = "/invoice/getinvoicedetails";

export const API_GET_MY_INVOICES = "/invoice/getinvoices";

export const API_INVOICE_ADD_NOTE = "/invoice/addnote";

export const API_DELETE_INVOICE = "/invoice/deleteinvoice";

export const API_ADD_REMOVE_TAG_INVOICE = "/invoice/addremovetaginvoice";

export const API_ADD_REMOVE_FOLLOWERS_INVOICE =
  "/invoice/addremovefollowersinvoice";

export const API_CREATE_GUEST_INVOICE = "/invoice/createguestinvoice";

// Payment start
export const API_GET_MY_PAYMENTS = "/payment/mypayments";

// Subscription start
export const API_GET_MY_SUBSCRIPTIONS = "/plan/mysubscriptions";

/*------ product url start ------*/
//get all product type
export const API_GET_ALL_PRODUCT_TYPE = "/product/getallproducttype";

/*------ product url end ------*/

/* activity process url start */
//save process
export const API_ADD_NEW_PROCESS = "/activityprocess/createprocess";

//save history
export const API_SAVE_PROCESS_HISTORY = "/activityprocess/saveprocesshistory";

//get process
export const API_GET_ALL_PROCESS = "/activityprocess/getallprocess";

//getprocessdetails
export const API_GET_PROCESS_DETAILS = "/activityprocess/getprocessdetails";

//updateprocess
export const API_UPDATE_PROCESS = "/activityprocess/updateprocess";

//updatekanabn
export const API_UPDATE_KANBAN = "/activityprocess/updatekanban";

//remove card kanban
export const API_REMOVE_CARD_ITEM_KANBAN = "/activityprocess/removecardkanban";

// Tickets start
export const API_GET_MY_TICKETS = "/tickets/getalltickets";

export const API_GET_AVG_SALES = "/tickets/avgsales";

export const API_GET_TICKET_BY_YEAR = "/tickets/ticketperyear";

export const API_GET_TICKET_BY_MONTH = "/tickets/ticketpermonth";

export const API_GET_SALES_BY_CHANNEL = "/tickets/salesbychannel";

export const API_GET_TICKET_DAYS_OF_WEEK = "/tickets/ticketperdayofweek";

export const API_GET_TICKET_BY_GENDER = "/tickets/ticketbygender";

export const API_GET_TICKET_BY_PRICE = "/tickets/ticketbyprice";

export const API_GET_TICKET_BY_PROMOTION = "/tickets/ticketbypromotion";

export const API_GET_TICKET_BY_SECTOR = "/tickets/ticketbysector";

export const API_GET_TICKET_BY_BILL = "/tickets/ticketbybill";

export const API_GET_TICKET_BY_WORK = "/tickets/ticketbywork";

export const API_GET_BEST_BUYER = "/tickets/bestbuyer";

export const API_SAVE_TICKETS_BYCSV = "/tickets/bulkupload";

export const API_GET_TICKET_INCOME = "/tickets/ticketsandincome";

export const API_GET_TICKET_EVENT_EARNING = "/tickets/earningbyevent";

export const API_GET_TICKET_CHANNEL_EARNING = "/tickets/earningbychannel";

export const API_GET_TICKET_ZONE_EARNING = "/tickets/earningbyzone";

// filter options
export const API_TICKETS_FILTER_OPTIONS = "/tickets/filteroptions";

/* tickets url end */

/*==== action logs ====*/
export const API_GET_ACTION_LOGS = "/actionlogs/getlogs";

//gpt

//create
export const API_CREATE_GPTPROMPTS = "/gptprompt/createprompt";

//get all prompts
export const API_GET_ALL_GPTPROMPTS = "/gptprompt/getprompts";

//details
export const API_GET_DETAILS_GPTPROMPT = "/gptprompt/getpromptdetails";

//update
export const API_UPDATE_GPTPROMPTS = "/gptprompt/updateprompt";

//** Filter url start */
//create filter
export const API_ADD_NEW_FILTER = "/filter/createfilter";

//get all filters
export const API_GET_ALL_FILTER = "/filter/getfilters";

//get default filter
export const API_GET_DEFAULT_FILTER = "/filter/getdefaultfilter";

//update filter
export const API_UPDATE_FILTER = "/filter/updatefilter";

//delete filter
export const API_DELETE_FILTER = "/filter/deletefilter";

//** Filter url end */
//alert url start
//add alert
export const API_ADD_NEW_ALERT = "/alert/createalert";

//get all alert
export const API_GET_ALL_TODAYS_ALERT = "/alert/gettodaysalerts";

//get all other days alert
export const API_GET_ALL_OTHERDAYS_ALERT = "/alert/getotherdaysalerts";

//get alert details
export const API_GET_ALERT_DETAILS = "/alert/getalert";

//update alert
export const API_UPDATE_ALERT = "/alert/updatealert";

//delete alert
export const API_DELETE_ALERT = "/alert/deletealert";

//alert url end

//note url start
//add note
export const API_ADD_NEW_NOTE = "/note/createnote";

//get all note
export const API_GET_ALL_NOTE = "/note/getallnotes";

//get all crm note
export const API_GET_ALL_CRM_NOTE = "/note/getallcrmnotes";

//get note details
export const API_GET_NOTE_DETAILS = "/note/getnotedetails";

//update note
export const API_UPDATE_NOTE = "/note/updatenote";

//delete note
export const API_DELETE_NOTE = "/note/deletenote";
//note url end

//course url start

//add new upload content
export const API_ADD_NEW_CONTENT = "/course/addnewcontent";

//add new course
export const API_ADD_NEW_COURSE = "/course/createcourse";

//add new chapter
export const API_ADD_NEW_COURSE_CHAPTER = "/course/addnewchapter";

//add new lesson
export const API_ADD_NEW_COURSE_LESSON = "/course/addnewlesson";

//add new member
export const API_ADD_NEW_COURSE_MEMBER = "/course/addnewmember";

//add new subscriber
export const API_ADD_NEW_COURSE_SUBSCRIBER = "/course/addnewsubscriber";

//get all course
export const API_GET_ALL_COURSES = "/course/getallcourse";

//get all parent course
export const API_GET_ALL_PARENT_COURSES = "/course/getallparentcourse";

//getcourselistforoptions
export const API_GET_COURSE_LIST_FOR_OPTIONS =
  "/course/getcourselistforoptions";

//getmemberlistoptions
export const API_GET_COURSE_MEMBER_LIST_OPTIONS =
  "/course/getmemberlistoptions";

//get all chapters
export const API_GET_ALL_COURSE_CHAPTERS = "/course/getallchapters";

//getchapterlistoptions
export const API_GET_COURSE_CHAPTER_LIST_OPTIONS =
  "/course/getchapterlistoptions";

//get all lessons
export const API_GET_ALL_COURSE_LESSONS = "/course/getalllessons";

//getlessonlistoptions
export const API_GET_COURSE_LESSON_LIST_OPTIONS =
  "/course/getlessonlistoptions";

//get all course members
export const API_GET_ALL_COURSE_MEMBERS = "/course/getallcoursemembers";

//get all course subscribers
export const API_GET_ALL_COURSE_SUBSCRIBERS = "/course/getallcoursesubscribers";

//getallsubmittedtasks
export const API_GET_ALL_COURSE_TASKS = "/course/getallcoursetask";

//getallcoursefaqlist
export const API_GET_ALL_COURSE_FAQ_LIST = "/course/getallcoursefaqlist";

//getcoursecertificatelist
export const API_GET_COURSE_CERTIFICATE_LIST =
  "/course/getcoursecertificatelist";

//get content details
export const API_GET_CONTENT_DETAILS = "/course/getcontentdetails";

//get course details
export const API_GET_COURSE_DETAILS = "/course/getcoursedetails";

//getcoursememberdetails
export const API_GET_COURSE_MEMBER_DETAILS = "/course/getcoursememberdetails";

//getcoursesubsriberdetails
export const API_GET_COURSE_SUBSCRIBER_DETAILS =
  "/course/getcoursesubsriberdetails";

//getcoursechapterdetails
export const API_GET_COURSE_CHAPTER_DETAILS = "/course/getcoursechapterdetails";

//getcourselessondetails
export const API_GET_COURSE_LESSON_DETAILS = "/course/getcourselessondetails";

//getcertificatedetails
export const API_GET_CERTIFICATE_DETAILS = "/course/getcertificatedetails";

//update content
export const API_UPDATE_CONTENT = "/course/updatecontent";

//update course
export const API_UPDATE_COURSE = "/course/updatecourse";

//update massive course
export const API_UPDATE_MASSIVE_COURSE = "/course/updatemassivecourse";

//updatecoursemember
export const API_UPDATE_COURSE_MEMBER = "/course/updatecoursemember";

//updatecoursechapter
export const API_UPDATE_COURSE_CHAPTER = "/course/updatecoursechapter";

//updatecoursesubscriber
export const API_UPDATE_COURSE_SUBSCRIBER = "/course/updatecoursesubscriber";

//updatemassivecoursechapters
export const API_UPDATE_MASSIVE_COURSE_CHAPTERS =
  "/course/updatemassivecoursechapters";

//updatecourselesson
export const API_UPDATE_COURSE_LESSON = "/course/updatecourselesson";

//updatemassivecourselessons
export const API_UPDATE_MASSIVE_COURSE_LESSONS =
  "/course/updatemassivecourselessons";

//delete course
export const API_DELETE_COURSE = "/course/deletecourse";

//delete massive course
export const API_DELETE_MASSIVE_COURSE = "/course/deletemassivecourse";

//deletecoursechapter
export const API_DELETE_COURSE_CHAPTER = "/course/deletecoursechapter";

//delete massive course chapter
export const API_DELETE_MASSIVE_COURSE_CHAPTERS =
  "/course/deletemassivecoursechapters";

//deletecourselesson
export const API_DELETE_COURSE_LESSON = "/course/deletecourselesson";

//delete massive course lesson
export const API_DELETE_MASSIVE_COURSE_LESSONS =
  "/course/deletemassivecourselessons";

//delete content
export const API_DELETE_CONTENT = "/course/deletecontent";

//addremovetagcourse
export const API_ADD_REMOVE_TAG_COURSE = "/course/addremovetagcourse";

//end course
